import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import {
  Hero, WorkingCapital, Antecipation, HomeEquity, RealEstateFinancing, PayrollLoan, CashFlow, GetToKnow
} from './sections/_index'

import { Wrapper } from './style'

function CreditoPJ () {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <WorkingCapital />
        <Antecipation />
        <HomeEquity />
        <RealEstateFinancing />
        <PayrollLoan />
        <CashFlow />
        <GetToKnow />
      </Layout>
    </Wrapper>
  )
}

export default CreditoPJ
