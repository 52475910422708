import React from 'react'
import ScrollTo from 'src/components/ScrollTo'

import { Section  } from './style'

const Hero = () => {

  return (
    <Section className='py-5 d-flex' role='img' aria-label='Cliente Inter Empresas conferindo as possibilidades de crédito para o seu negócio no Super App'>
      <div className='container d-flex align-items-center'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 content px-5 px-md-0 py-5'>
            <h1 className='fs-28 fs-lg-40 fs-xl-56 lh-33 lh-lg-45 lh-xl-60 text-white fw-500 mb-3'>
              <span className='d-lg-block'>Empréstimos para</span> <span className='d-lg-block d-xl-inline'>empresas: soluções de</span> <span className='d-lg-block d-xl-inline'>crédito completas para o</span> seu negócio
            </h1>
            <p className='fs-16 fs-xl-18 lh-20 lh-lg-22 text-white fw-400'>Alavancar a sua empresa pode ser simples. Veja nossas opções de empréstimo e dê início ao futuro do seu negócio hoje.</p>
            <ScrollTo
               styles='fs-14 fw-600 btn btn--lg btn-orange--extra text-none rounded-3 mt-3 mt-md-0'
               to='#working-capital'
               section='dobra_01'
               sectionName='Empréstimos para empresas: soluções de crédito completas para o seu negócio'
               elementName='Saber mais'
               title='Saber mais'
             >
               Saber mais
             </ScrollTo>
         </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
