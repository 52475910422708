import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'

export const Section = styled.section`
  @media (min-width: ${breakpoints.md}){
    background-color: ${orange.dark};
  }

  a {
    outline: none!important;
    border:none!important;
  }
  
  .content {
    background-color: ${orange.dark};
    border-radius: 130px 60px 130px 60px;

    @media (min-width: ${breakpoints.md}){
      background-color: transparent;
      border-radius: none;
    }
  }
  
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/creditopj-dobra1/image.webp');
    height: 384px;
    background-position: 408px 0;
    background-repeat: no-repeat;
    background-size: contain;

    a {
      max-width: 327px;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    height: 433px;
    background-position: top right;
    background-size: contain;

    a {
      max-width: 456px;
    }
  }

  @media (min-width: ${breakpoints.xl}){
    height: 573px;
    background-position: top right;
    background-size: contain;

    a {
      max-width: 454px;
    }
  }

  @media (min-width: ${breakpoints.xxxl}){
    background-position: top right;
    background-size: 786px;
    height: 749px;
  }
`
