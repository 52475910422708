import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
    a {
      @media (min-width: ${breakpoints.lg}){
        max-width: 376px;
      }
      @media (min-width: ${breakpoints.xl}){
        max-width: 454px;
      }
    }
    .underline {
      text-decoration: underline;
    }
  }
`
