import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'

export const Section = styled.section`
  background-color: ${orange.dark};
    
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/creditopj-dobra6-768/image.webp');
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
    height: 432px;
  }
  
  @media (min-width: ${breakpoints.lg}){
    background-position: top left;
    height: 360px;
    background-size: 340px;
  }
  @media (min-width: ${breakpoints.xl}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/emprestimo-creditopj/image.webp');
    min-height: 619px;
    background-size: contain;
  }

  a {
    @media (min-width: ${breakpoints.md}){
      max-width: 332px;
    }
    @media (min-width: ${breakpoints.lg}){
      max-width: 459px;
    }
    @media (min-width: ${breakpoints.xl}){
      max-width: 454px;
    }
  }
`
