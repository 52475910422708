import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const Antecipation = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-center' role='img' aria-label='Uma mulher sorrindo, em seu restaurante, enquanto usa um tablet'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6'>
            <div className='bg rounded-4 px-3 px-lg-4 py-4 py-lg-5'>
              <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 fw-500 mb-3'>
                Antecipação de recebíveis: receba vendas e notas quando quiser
              </h2>
              <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500'>
                Adiante o valor de suas vendas ou notas com vencimento futuro, receba pagamentos à vista e garanta fluxo de caixa de forma supersimples e rápida.  
              </p>
              <a
                title='Saber mais'
                className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-3 mt-md-2'
                href='/empresas/emprestimos/antecipacao-de-recebiveis/'
                target='blank'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_name: 'Saber mais',
                    section_name: 'Antecipação de recebíveis: receba vendas e notas quando quiser',
                    redirect_url: `${window.location.origin}/empresas/emprestimos/antecipacao-de-recebiveis/`
                  })
                }}
              >
                Saber mais
              </a>
            </div>
         </div>
        </div>
      </div>
    </Section>
  )
}

export default Antecipation
