import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'
import { gray } from 'src/styles/colors'

export const Section = styled.section`

`

export const InfoCredit = styled.div`
  background-color: ${orange.dark};
  border-radius: 30px;

  @media (min-width: ${breakpoints.md}){
    background-color: ${orange.dark};
    border-radius: 30px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/credito-768/image.webp');
    background-repeat: no-repeat;
    background-position: top right;
    min-height: 237px;
    width: 100%;
  }

  @media (min-width: ${breakpoints.lg}){
    min-height: 195px;
    background-position: top right;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/creditopj-dobra8/image.webp');
  }

  p {
    @media (min-width: ${breakpoints.md}){
      width: 244px;
    }
    @media (min-width: ${breakpoints.lg}){
      width: 366px;
    }
    @media (min-width: ${breakpoints.xl}){
      width: 518px;
    }
  }
`

export const Card = styled.div`
  background-color: ${gray[100]};
  border-radius: 30px;

  @media (min-width: ${breakpoints.md}){
    min-height: 185px;
  }

  @media (min-width: ${breakpoints.lg}){
    min-height: 208px;
  }

  @media (min-width: ${breakpoints.xl}){
    min-height: 186px;
  }
`