import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { sand } from 'src/styles/newColors'
import { white, orange } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${sand};

  .react-multi-carousel-dot{
    button {
      background-color: ${orange.extra}
    }
  }

 .react-multi-carousel-track {
    @media (min-width: ${breakpoints.md}){
      padding: 30px 0px 0;
    }

    @media (min-width: ${breakpoints.xxl}){
      padding: 30px 0px 30px;
    }
  }
`

export const Card = styled.div`
  background-color: ${white};
  min-height: 217px;
  border-radius: 30px;

  @media (min-width: ${breakpoints.lg}){
    min-height: 197px;
  }
`
