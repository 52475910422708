import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, InfoCredit, Card } from './style'

const FindOutMore = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 mt-3'>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3 mb-md-4 mb-lg-5'>
              Conheça mais opções de Crédito Empresarial
            </h2>
         </div>
         <InfoCredit className='px-3 py-4 pt-md-5 pl-md-4 pt-lg-5 pl-lg-5 mb-3 mb-lg-5'>
            <h3 className='fs-24 lh-28 text-white'>Crédito Rural</h3>
            <p className='fs-18 lh-22 text-white'>Financie custos de atividades agrícolas e pecuárias de uma forma muito mais simples</p>
         </InfoCredit>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6 mb-3 px-0'>
            <Card className='px-3 py-4'>
              <h3 className='fs-20 fs-lg-24 lh-24 lh-lg-28'>Antecipação de Recebíveis do cartão de crédito</h3>
              <p className='fs-14 fs-lg-18 lh-17 lh-lg-22'>Receba suas vendas de cartão em 2 dias úteis e com as melhores taxas de antecipação do mercado</p>
            </Card>
          </div>
          <div className='col-12 col-md-6'>
            <Card className='px-3 py-4'>
              <h3 className='fs-20 fs-lg-24 lh-24 lh-lg-28'>Carta Fiança</h3>
              <p className='fs-14 fs-lg-18 lh-17 lh-lg-22'>Tenha nossa cobertura para realizar negócios comerciais que exigem uma garantia bancária</p>
            </Card>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FindOutMore
