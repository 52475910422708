import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { white, grayscale } from 'src/styles/colors'
import { orange } from 'src/styles/newColors'

export const Section = styled.section`
  background-color: ${orange.dark};

  .bg {
    h2 { 
      color: ${white};
      @media (min-width: ${breakpoints.md}) { 
        color: ${grayscale[500]};
      }    
    }
    p { 
      color: ${white};
      @media (min-width: ${breakpoints.md}) { 
        color: ${grayscale[400]};
      }   
     }
   
   @media (min-width: ${breakpoints.md}) { 
      background-color: ${white};
    }
  }  

  @media (min-width: ${breakpoints.md}){ 
    background-color: transparent;
  }
  
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/creditopj-dobra3/image.webp');
    background-size: contain;
    background-position: top center;
    background-size: 960px;
    background-repeat: no-repeat;
    height: 419px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 494px;
    background-size: cover;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 712px;
    background-size: cover;
  }

  @media (min-width: ${breakpoints.xxxl}){
    height: 940px;
    background-size: cover;
  }

  a {
    @media (min-width: ${breakpoints.md}){
      max-width: 100%;
    }
  }
`
