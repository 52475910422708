import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'

const RealEstateFinancing = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 bg-gray'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 d-flex justify-content-center order-md-last'> 
            <ImageWebp
              arrayNumbers={[ 261, 261, 374, 452 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/creditopj-dobra5/image.webp'
              altDescription='Um homem falando no celular enquanto segura em uma caixa. Ao fundo vemos uma caixa e outras caixas'
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 mt-3'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
              Financiamento Imobiliário: mais facilidade para garantir seu espaço físico
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              Financie em até 75% o valor do imóvel da sua empresa com o Inter Empresas e escolha como pagar, em até 30 anos  
            </p>
            <a
              title='Saber mais'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-3'
              href='/empresas/financiamento-imobiliario/comercial/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Saber mais',
                  section_name: 'Capital de Giro sem burocracias: conheça o FGI Peac  ',
                  redirect_url: `${window.location.origin}/empresas/financiamento-imobiliario/comercial/`
                })
              }}
            >
              Saber mais
            </a>
         </div>
        </div>
      </div>
    </Section>
  )
}

export default RealEstateFinancing
